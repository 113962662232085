<template>
  <div class="sticky mb-" style="display: flex; align-items: center">
    <div class="card text-center p-2" style="width: 100%">
      <h5 class="mb-0">{{ title }}</h5>
      <div class="mb-1">
        <div class="mb-2">
          <img
            class="rounded-circle shadow-lg"
            width="80"
            height="80"
            :src="
              avatar
                ? avatar.route
                : 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/user-icon-placeholder.png'
            "
          />
        </div>
        <div class="">
          <h6 class="text-primary">{{ name }}</h6>
        </div>
      </div>
      <ul class="listing-details-sidebar mb-0">
        <li>
          <a :href="'tel:' + phone1"
            ><i class="fa fa-phone"></i> {{ phone1 }} {{ phone2 ? '/ ' + phone2 : '' }}</a
          >
        </li>
        <li v-if="website">
          <a :href="website" target="_blank" rel="noopener"
            ><i class="fa fa-external-link"></i> {{ website }}</a
          >
        </li>
        <li v-if="email">
          <a :href="'mailto:' + email"><i class="fa fa-envelope-o"></i>{{ email }}</a>
        </li>
        <li v-if="address"><i class="fa fa-map"></i>{{ address }}</li>
      </ul>
      <!--Haz click para solicitar información
			<div class="text-center">
				<v-btn
					style="margin: 10px auto"
					dark
					color="#003a56"
					@click.prevent="$emit('showContactForm')"
				>
					Contactar
				</v-btn>
			</div>-->
    </div>
  </div>
</template>

<script>
  export default {
    props: ['title', 'name', 'phone1', 'phone2', 'website', 'email', 'avatar', 'address'],
  }
</script>

<style lang="scss" scoped>
  .listing-details-sidebar li {
    font-size: 13px;
  }
</style>
